import StrapiService from "@/Services/Strapi/Strapi.service";
import { specialComponentsData } from "./BasePageData.model";
import { LocalizationMap } from "@/staticData/LocalizationMap";
import SpecialSectionsLogicController from "./SpecialSectionsLogic/SpecialSectionsLogic.controller";
import ApolloClientManager from "../GraphQL/ApolloClientManager";

class BasePageDataController {
  constructor() {
    this.BASE_URL = "https://outscal.com";
    this.apolloClient = ApolloClientManager.client;
    this.specialSectionsLogicController = new SpecialSectionsLogicController(
      specialComponentsData
    );
    this.url = null;
    this.baseQuery =
      "populate[sections][populate][showOn][populate]=*&populate[sections][populate]=*&populate[seo][populate]=*&populate[faqs]=*&populate[sections][populate][interLinks][populate]=*&populate[sections][populate][tabs][populate]=*";
    this.envFilter = `filters[env][$eq]=${process.env.REACT_APP_ENV === "production" ? "PRODUCTION" : "QA"
      }`;
    this.sortBy = "sort=priority:desc&pagination[limit]=1";
    this.customDataComponents = {
      "section.faq": (pageData, section) => {
        const faqsList = this.#getFaqsList(pageData);
        return {
          ...section,
          listData: faqsList,
        };
      },
      "section.tab-links": (pageData, section) => {
        let tabs = this.#constructTabsObj(section?.tabs || {});
        section.tabs = tabs;
        return { ...section };
      },
    };
  }

  initialize(url) {
    this.url = url;
  }

  #getFaqsList(pageData) {
    let faqsList = pageData?.faqs?.data?.map((item) => item);
    return faqsList || [];
  }

  formatCustomDataSections(pageData) {
    try {
      const faqsList = this.#getFaqsList(pageData);
      const sectionsDataWithFaqsList = pageData?.sections.map((item) => {
        if (this.customDataComponents[item.__component]) {
          return this.customDataComponents[item.__component](pageData, item);
        }
        return item;
      });

      return {
        ...pageData,
        sections: sectionsDataWithFaqsList,
        faqs: faqsList,
      };
    } catch (error) {
      console.error("Error sanitizing page data:", error);
      return null;
    }
  }

  async getCustomPageData(url, pageData) {
    try {
      return null;
    } catch (error) {
      console.error("Error returning custom page data:", error);
      return null;
    }
  }

  makeAlternateLanguageData(url, locale, defaultLocale = "in") {
    try {
      const formattedUrl = url.split("/").slice(1).join("/");

      const result = Object.keys(LocalizationMap).map((key) => {
        return {
          href: `${process.env.REACT_APP_WEBSITE_URL}${key !== defaultLocale ? `${key}/` : ""
            }${formattedUrl}`,
          hrefLang: LocalizationMap[key],
        };
      });

      const defaultUrlObject = {
        href: `${process.env.REACT_APP_WEBSITE_URL}${formattedUrl}`,
        hrefLang: "x-default",
      };

      const canonicalUrl = `${process.env.REACT_APP_WEBSITE_URL}${locale && locale !== defaultLocale ? `${locale}/` : ""
        }${formattedUrl}`;

      return {
        languageAlternates: [...result, defaultUrlObject],
        canonicalUrl,
      };
    } catch (error) {
      console.error(error);
      return [];
    }
  }

  async getPageDataFromStrapi(
    url,
    pageQuery = "",
    locale = "in",
    defaultLocale = "in"
  ) {
    const commonFilter = `&${this.envFilter}&filters[$or][0][url][$eq]=${url}&filters[$or][1][url][$eq]=${this.url}&${this.sortBy}`;
    const primaryLocaleFilter = `locale=${LocalizationMap[locale]}${commonFilter}`;
    const defaultLocaleFilter = `locale=${LocalizationMap[defaultLocale]}${commonFilter}`;

    try {
      let response = await StrapiService.get(
        "/pages",
        primaryLocaleFilter,
        `${this.baseQuery}${pageQuery}`
      );

      if (response && response.sections.length > 0) {
        return response;
      } else {
        throw new Error(
          "Primary locale data not found, fetching default locale"
        );
      }
    } catch (error) {
      console.error("Warnign: Error fetching primary locale:", error.message);
      return await StrapiService.get(
        "/pages",
        defaultLocaleFilter,
        `${this.baseQuery}${pageQuery}`
      );
    }
  }

  #removeHyphensAndTrailingDigits = (name) => {
    return this.#removeTrailingDigits(name)
      .split("-")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  #removeTrailingDigits = (str) => str.replace(/-\d+$/, "");

  #constructTabsObj(myTabs) {
    const tabs = {};
    myTabs.data.forEach((tab) => {
      const tabKey = this.#removeHyphensAndTrailingDigits(tab.name);
      tabs[tabKey] = {
        label: tabKey,
        data: tab.listData.map((item) => ({
          label: this.#removeHyphensAndTrailingDigits(item.name),
          link: item.link,
        })),
        link: this.#removeTrailingDigits(tab.name),
      };
    });
    return tabs;
  }

  checkRedirection(pageData) {
    const { redirectionUrl, redirectionCode } = pageData?.seo || {};
    return redirectionUrl && redirectionCode
      ? {
        redirect: {
          destination: redirectionUrl,
          statusCode: redirectionCode,
        },
      }
      : null;
  }
  setUpRelCanonical(alternateLanguageData, pageData) {
    let { canonicalUrl = "" } = alternateLanguageData || {};
    canonicalUrl = pageData?.seo?.relCanonical || canonicalUrl;
  }

  async getPageData(url, pageQuery, locale, defaultLocale = "in", onlyCustomData = false) {
    try {
      let pageData = {};
      if (!onlyCustomData) {
        pageData = await this.getPageDataFromStrapi(
          url,
          pageQuery,
          locale,
          defaultLocale
        );
        pageData =
          await this.specialSectionsLogicController.populateSpecialSectionsData(
            pageData
          );
        pageData = this.formatCustomDataSections(pageData);
        pageData = this.fixBreadcrumbItemListUrl(pageData);
      }
      let customPageData = await this.getCustomPageData(url, pageData);

      let alternateLanguageData = this.makeAlternateLanguageData(
        url,
        locale,
        defaultLocale
      );

      this.setUpRelCanonical(alternateLanguageData, pageData);

      return {
        pageData,
        customPageData: customPageData || null,
        alternateLanguageData,
      };
    } catch (error) {
      console.error("Error fetching page data:", error);
      return null;
    }
  }

  fixBreadcrumbItemListUrl(pageData) {
    if (pageData?.seo?.breadcrumbs) {
      pageData.seo.breadcrumbs = pageData.seo.breadcrumbs.map(
        ({ item, ...rest }) => ({
          item: item.replace(
            /^(https?:\/\/[^\/]+)?(\/.*)?$/,
            `${this.BASE_URL}$2`
          ),
          ...rest,
        })
      );
    }
    return pageData;
  }
}

export default BasePageDataController;
